import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="clipboard"
export default class extends Controller {
  static targets = ["source", "initialContent", "notice"];
  declare sourceTarget: HTMLInputElement;
  declare initialContentTarget: HTMLInputElement;
  declare noticeTarget: HTMLInputElement;

  static classes = ["hidden"];
  declare hiddenClass: string;

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.value);
    this.initialContentTarget.classList.toggle(this.hiddenClass);
    this.noticeTarget.classList.toggle(this.hiddenClass);
    setTimeout(() => {
      this.initialContentTarget.classList.toggle(this.hiddenClass);
      this.noticeTarget.classList.toggle(this.hiddenClass);
    }, 2000);
  }
}
